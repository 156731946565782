
import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: "SingleFooter",
    components: {
        //
    },
})
export default class SingleFooter extends Vue {
    private cloudDomain: boolean = document.domain.indexOf('.morewis.com') > -1;
    private baseUrlIframe = process.env.VUE_APP_URL;
    private navLink(type: number) {
        if (type === 4) {
            // window.location.href = '/mall/index.htm';
            this.$router.push('/mall');
        } else if (type === 8) {
            // window.location.href = this.baseUrlIframe + '/developer/index.htm';
            this.$router.push('/developerCenter');
        }
    }
    get getCurrentYear() {
        return new Date().getFullYear();
    }
}
