
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import UpdateDomainDialog from "@/components/updateDomainDialog.vue";

let p = 0;
let t = 0;

@Component({
    name: "SingleHeader",
    components: {
        UpdateDomainDialog,
    },
})
export default class SingleHeader extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    })
    private closeHandleScroll!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Boolean,
        default: false,
    })
    private closeHeaderHidden!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Boolean,
        default: false,
    })
    private transientHidden!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Number,
        default: 1,
    })
    private selectIndex!: number; // 接收父组件传过来的值
    private cloudDomain: boolean =
        document.domain.indexOf('.morewis.com') > -1 || document.domain.indexOf('xwww.morewiscloud.com') > -1;
    private contentTitle: string = "单个页面导航栏";
    private headClass: any = ['header'];
    private isWhiteImg: boolean = false;
    private infoFlag: boolean = false;
    private baseUrlIframe = process.env.VUE_APP_URL;
    private userInfo: any = {}; // 用户信息
    private cert: number = 0; // 已认证
    private baseUrl: string = '';
    private isLogin: boolean = false;
    private screenWidth: any = null;
    private showUpdateDomain: boolean = false;

    @Watch('screenWidth')
    private screenWidthChange(val: any) {
        if (val <= 1366) {
            if (!this.headClass.includes('header_1366')) {
                this.headClass.push('header_1366');
            }
            if (this.headClass.includes('header_1600')) {
                this.headClass.splice(this.headClass.findIndex((i: any) => i === "header_1600"), 1);
            }
        } else if (val <= 1600) {
            if (!this.headClass.includes('header_1600')) {
                this.headClass.push('header_1600');
            }
            if (this.headClass.includes('header_1366')) {
                this.headClass.splice(this.headClass.findIndex((i: any) => i === "header_1366"), 1);
            }
        } else {
            if (this.headClass.includes('header_1600')) {
                this.headClass.splice(this.headClass.findIndex((i: any) => i === "header_1600"), 1);
            }
            if (this.headClass.includes('header_1366')) {
                this.headClass.splice(this.headClass.findIndex((i: any) => i === "header_1366"), 1);
            }
        }
    }
    private created() {
        this.$nextTick( () => {
            this.getUserCurrent();
            if (process.env.NODE_ENV === 'development') {
                this.baseUrl = '/apiProxy';
            }
            // const url = window.location.href;
            // if (url.indexOf(`www.morewiscloud.com`) > -1) {
            //     this.showUpdateDomain = true;
            // }
        });
    }
    private async getUserCurrent() {
        // 获取当前用户信息
        if (await this.$isLogin()) {
            this.isLogin = true;
            this.$httpService.getData({}, '/apiProxy/api/frontend/users/current').then((res: any) => {
                this.$store.commit('saveUserInfo', res);
                this.userInfo = res;
                this.cert = this.userInfo.certStatus;
            }).finally(() => {
                this.infoFlag = true;
            });
        } else {
            this.isLogin = false;
            this.infoFlag = true;
        }
    }
    private mounted() {
        p = 0;
        t = 0;
        if (!this.closeHandleScroll) {
            window.addEventListener("scroll", this.handleScroll, true);
        }
        this.screenWidth = window.screen.width || document.body.clientWidth;
        window.onresize = () => {
            return (() => {
                this.screenWidth = window.innerWidth || document.body.clientWidth;
            })();
        };
    }
    private destroyed() {
        window.removeEventListener('scroll', this.handleScroll, true);
    }
    private handleScroll() {
        const headEl: any = this.$refs.fixedHeader;
        // let showHeadState = true;

        p = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        if (t <= p) {
            // 向下滚
            if (!this.headClass.includes('header_white')) {
                this.headClass.push("header_white");
                this.isWhiteImg = true;
            }
            if (document.documentElement.scrollTop >= 101 && !this.closeHeaderHidden) {
                if (!this.headClass.includes('header_hidden')) {
                    this.headClass.push("header_hidden");
                }
            }
        } else {
            // 向上滚
            if (document.documentElement.scrollTop <= 0) {
                this.headClass.splice(this.headClass.findIndex((i: any) => i === "header_white"), 1);
                this.isWhiteImg = false;
            }
            if (this.headClass.includes('header_hidden') && !this.transientHidden) {
                this.headClass.splice(this.headClass.findIndex((i: any) => i === "header_hidden"), 1);
            }
        }
        setTimeout(() => {
            t = p;
        }, 0);
    }
    private mouseoverHeader() {
        if (!this.headClass.includes('header_white')) {
            this.headClass.push("header_white");
            this.isWhiteImg = true;
        }
    }
    private mouseoutHeader() {
        if (this.headClass.includes('header_white') && document.documentElement.scrollTop <= 0) {
            this.headClass.splice(this.headClass.findIndex((i: any) => i === "header_white"), 1);
            this.isWhiteImg = false;
        }
    }
    private onLogin() {
        const baseUrl = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '';
        if (this.cloudDomain) {
            this.$router.push("/login").catch((err) => err);
        } else {
            window.location.href = this.baseUrlIframe + baseUrl + '/login';
        }
    }
    private onRegister() {
        const baseUrl = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '';
        if (this.cloudDomain) {
            this.$router.push("/register").catch((err) => err);
        } else {
            window.location.href = this.baseUrlIframe + baseUrl + '/register';
        }
    }
    private openCompanyOrPerson() {
        if (this.userInfo.userType === 1) {
            this.$router.push("/person").catch((err) => err);
        } else if (this.userInfo.userType === 2) {
            this.$router.push("/company").catch((err) => err);
        } else {
            this.$router.push("/company").catch((err) => err);
        }
    }
    // 修改密码
    private changePassword() {
        if (this.userInfo.userType === 2) {
            this.$router.push({ path: "/company/security"}).catch((err) => err);
        } else if (this.userInfo.userType === 1) {
            this.$router.push({ path: "/person/perSecurity"}).catch((err) => err);
        }
    }
    // 我的订单
    private gotoOrder(orderStatus: string) {
        console.log('gotoOrder');
        if (this.userInfo.userType === 2) {
            this.$router.push({ path: "/company/myOrder", query: {orderStatus}}).catch((err) => err);
        }
    }
    // 我的应用
    private gotoApp() {
        if (this.userInfo.userType === 2) {
            this.$router.push({ path: "/sale/myApp"}).catch((err) => err);
        }
    }
    // 去消息列表
    private openMessage() {
        this.$router.push({ path: "/company/message"}).catch((err) => err);
    }

    private onExit() {
        this.$httpService.postData({}, '/apiProxy/api/frontend/passport/logout').then((res: any) => {
            if (res) {
                // 清除cookie
                const seesionToken = localStorage.getItem('session_token');
                const legacyieName = localStorage.getItem('legacy_cookieName');
                const legacyDomain = localStorage.getItem('legacy_cookieDomain');
                document.cookie = `${legacyieName}=${seesionToken};domain=${legacyDomain};path=/;max-age=0`;
                // 清除各种缓存数据
                localStorage.clear();
                // 刷新页面
                this.$router.go(0);
            }
        });
    }
    // 跳转
    private navLink(type: number) {
        if (type === 1) {
            this.$router.push('/index');
        } else if (type === 2) {
            window.open('https://www.morewis.com/');
        } else if (type === 3) {
            window.open('https://ntalk.morewis.com/web/#/home');
        }
    }
    private gotoCompanyRouter(type: number) {
        if (type === 1) {
            this.$router.push('/company/material');
        } else if (type === 2) {
            this.$router.push('/company/security');
        } else if (type === 3) {
            this.$router.push('/company/resource');
        } else if (type === 4) {
            this.gotoOrder('0');
        }
    }
    private gotoPersonRouter(type: number) {
        if (type === 1) {
            this.$router.push('/person/perMaterial');
        } else if (type === 2) {
            this.$router.push('/person/perSecurity');
        }
    }
    // 去认证
    private openCert() {
        this.$router.push({ path: "/company/Certification",
        query: { isBack: '0', isCert: '1' }}).catch((err) => err);
    }
    // 导航栏切换
    private selectIndexClick(index: number) {
        if (Number(this.selectIndex) !== index) {
            const baseUrl = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '';
            if (index === 1) {
                this.$router.push('/index');
            } else if (index === 2) {
                if (this.cloudDomain) {
                    this.$router.push("/mall").catch((err) => err);
                } else {
                    window.location.href = this.baseUrlIframe + baseUrl + '/mall';
                }
            } else if (index === 3) {
                if (this.cloudDomain) {
                    this.$router.push("/developerCenter").catch((err) => err);
                } else {
                    window.location.href = this.baseUrlIframe + baseUrl + '/developerCenter';
                }
            } else if (index === 4) {
                if (this.cloudDomain) {
                    this.$router.push("/experienceCenter").catch((err) => err);
                } else {
                    window.location.href = this.baseUrlIframe + baseUrl + '/experienceCenter';
                }
            } else if (index === 5) {
                if (this.cloudDomain) {
                    this.$router.push("/industryLibrary").catch((err) => err);
                } else {
                    window.location.href = this.baseUrlIframe + baseUrl + '/industryLibrary';
                }
            } else if (index === 6) {
                if (this.cloudDomain) {
                    this.$router.push("/documentCenter").catch((err) => err);
                } else {
                    window.location.href = this.baseUrlIframe + baseUrl + '/documentCenter';
                }
            }
        }
    }
}
